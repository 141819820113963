import { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useHistory, useLocation } from "react-router";
import useCurrentService from "../../utils/useCurrentService";
import _ from "lodash";

const noListener = () => false;
export default ({ messagePrefix = "SERVICE", listener = noListener, shouldListen = true, trackKey, setWizardOpen, subTabs, featureId, scope }) => {

    const initializationData = useRef({}); const history = useHistory();

    const apiResponses = useSelector(state => state.occmProxy?.responses);
    const { idTokenPayload: userMetadata, accessToken, thirdPartyToken } = useSelector(state => state.auth);
    const { selectedAgent: connectorAgent, selectedAgentId: connectorId, selectedAccountId: accountId, selectedWorkspaceId: workspaceId, isDemo: isDemoMode, selectedOrgId: organizationId, selectedScopeId: scopeId } = useSelector(state => state.tenancy);
    const location = useLocation();
    const dispatch = useDispatch();
    const currentService = useCurrentService();
    const connectorName = connectorAgent?.name;
    const features = useSelector(state => ({
        byKey: state.features.byKey,
        active: state.features.active
    }), shallowEqual);

    let selection = undefined;

    if (location?.state?.selection) {
        if (location.state.selection?.workingEnvironments) {
            selection = {
                workingEnvironments: location.state.selection.workingEnvironments
            };
        } else if (location.state.selection?.workingEnvironment) {
            selection = {
                workingEnvironment: location.state.selection.workingEnvironment
            };
        }
    }

    const getBaseUrl = () => {
        const isParentTab = !_.has(currentService.uiIntegration, 'subTabs');
        if (isParentTab) return currentService.key;
        const tabWithMfe = _.find(currentService.uiIntegration.stageIntegrations, stageIntegration => stageIntegration.scope === scope);
        return `/${currentService.key}${tabWithMfe.path}`;
    }

    const connectorIdFromLocation = location.state?.connectorId;

    initializationData.current = {
        userMetadata: window.btoa(JSON.stringify(userMetadata)),
        ...location.state,
        accessToken: accessToken,
        accountId: accountId === "no-project" ? undefined : accountId,
        workspaceId,
        organizationId: organizationId || undefined,
        scopeId: scopeId || undefined,
        connectorId: connectorIdFromLocation || connectorId || undefined,
        agentId: location.pathname?.startsWith("/governance") ? connectorId?.substring(0, connectorId.length - 7) : (connectorId || undefined),
        isDemoMode: isDemoMode || false,
        fromService: location?.state?.fromService || "direct",
        fromAction: _.get(location, "state.action"),
        selection: selection ? window.btoa(JSON.stringify(selection)) : undefined,
        pathname: location.pathname,
        hash: location.hash,
        domain: window.location.host,
        search: location.search,
        features,
        subTabs,
        featureId,
        connectorName: connectorName,
        baseUrl: getBaseUrl(),
        theme: features.active["Platform.BlueXP/DarkTheme"] ? "dark": "light"
    };

    const sendMessage = useCallback(({ type, payload }) => {
        window.postMessage({
            type: `${messagePrefix}:${type}`,
            payload
        });
    }, [messagePrefix]);

    useEffect(() => {
        if (shouldListen) {
            const receiveMessage = (event) => {
                if (_.isObject(event.data)) {
                    const { type, payload } = event.data;
                    console.log(type?.substring(messagePrefix.length + 1))
                    switch (type?.substring(messagePrefix.length + 1)) {
                        case "SERVICE:READY":
                        case "READY": {
                            return;
                        }
                        case "SERVICE:NAVIGATE":
                        case "NAVIGATE": {
                            if (payload.hash) {
                                history.replace({
                                    ...history.location,
                                    hash: payload.hash
                                })
                            } else {
                                history.push(payload.pathname, {
                                    ...payload.state,
                                    fromService: "-",
                                    action: payload.action,
                                    selection: payload?.selection?.extraParams
                                })
                            }
                            return;
                        }
                        case "OCCM-PROXY": {
                            dispatch({
                                type: 'SERVICE:OCCM-PROXY', payload
                            });
                            return;
                        }
                        case "SERVICE:ADD-NSS":
                        case "ADD-NSS": {
                            dispatch({
                                type: "SUPPORT:ADD-NSS-OPEN-POPOVER",
                                track: `from:${trackKey}`,
                                payload
                            })
                            return;
                        }
                        case "SERVICE:NOTIFICATION": {
                            dispatch({
                                type: payload.type,
                                payload: {
                                    message: payload.message,
                                    exception: payload.exception,
                                    group: payload.group
                                }
                            });
                        }
                        case "OPEN-WIZARD": {
                            setWizardOpen(true);
                            return;
                        }
                        case "CLOSE-WIZARD": {
                            setWizardOpen(false);
                            return;
                        }
                        default: {
                            const matched = listener(event.data);
                            if (!matched) {
                                console.log(`Parent APP received unknown message type - ${type}`);
                            }
                            return;
                        }
                    }
                }

            };

            window.addEventListener("message", receiveMessage, false);

            return () => {
                window.removeEventListener("message", receiveMessage)
            }
        }
    }, [messagePrefix, history, listener, shouldListen, dispatch, trackKey, location.state?.onReadyMessage, sendMessage]);

    useEffect(() => {
        if (apiResponses.length > 0) {
            const messageGroups = _.groupBy(apiResponses, "type");

            if (messageGroups["OCCM-PROXY-RESPONSE"]?.length > 0) {
                sendMessage({
                    type: "OCCM-PROXY-RESPONSE",
                    payload: { apiResponses: _.map(messageGroups["OCCM-PROXY-RESPONSE"], "payload") }
                })
            }

            if (messageGroups["NSS-ADDED"]?.length > 0) {
                sendMessage({
                    type: "NSS-ADDED"
                })
            }

            if (messageGroups["ADD-NSS-FAILED"]?.length > 0) {
                sendMessage({
                    type: "ADD-NSS-FAILED"
                })
            }

            dispatch({
                type: 'OCCM-PROXY:RESPONSES-HANDLED', payload: apiResponses
            });
        }

    }, [sendMessage, apiResponses, dispatch]);

    useEffect(() => {
        sendMessage({
            type: "CONNECTOR-CHANGE",
            payload: {
                connectorId,
                connectorName
            }
        })
    }, [sendMessage, connectorId, connectorName]);

    useEffect(() => {
        sendMessage({
            type: "WORKSPACE-CHANGE",
            payload: {
                workspaceId,
                scopeId
            }
        })
    }, [sendMessage, workspaceId]);

    useEffect(() => {
        sendMessage({
            type: "TOKEN-UPDATE",
            payload: {
                accessToken,
                userMetadata
            }
        })
    }, [sendMessage, accessToken, userMetadata]);

    const { pathname, hash, search } = location
    useEffect(() => {
        sendMessage({
            type: "LOCATION-CHANGE",
            payload: { pathname, hash, search, featureId }
        })
    }, [sendMessage, pathname, hash, search, featureId]);

    return { initializationData: initializationData.current }
};