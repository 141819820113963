import Auth from "utils/auth/Auth";
import querystring from "query-string";
import {history} from "store";
import {setAccessToken} from "utils/clients";
import _ from "lodash";
import externals from "utils/externals";
import {setUserId} from "utils/localstorage";
import { setDealTaleAnalytics, setFullStory, setGoogleAnalytics, trackGaBeforeLogin } from "utils/ga";
import { handlePromiseAction } from "utils/actions";
import { matchPath } from "react-router-dom";
import { trackEvent } from "utils/mrTracker";

const handleLocationReplacements = authResult => {
    const search = querystring.parse(window.location.search);

    if (authResult.paramsForUI.pathname) { // pathname received from login page. Navigate to it.
        const path = authResult.paramsForUI.pathname.startsWith('/') ? authResult.paramsForUI.pathname : `/${authResult.paramsForUI.pathname}`;
        history.replace(path);
    } else {
        delete search.redirectToLogin;
        delete search.paramsForLoginPage;
        const searchString = querystring.stringify(search);

        history.replace(window.location.pathname + (searchString ? `?${searchString}` : '') + window.location.hash);
    }
};

let auth = null;
const emailRegex = /^(?=.{0,255}$)[a-zA-Z0-9_+-]+(\.[a-zA-Z0-9_+-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.([a-zA-Z]{2,20})$/;
export default ({dispatch, getState}) => next => async (action) => {
    const {type, payload} = action;

    if (type === "AUTH:INITIALIZE") {
        const {domain, clientId: clientID, audience} = payload;
        const search = querystring.parse(window.location.search);
        /*
         * For AIQ user migration check if the email is part of the URL
         * Also validate the email id.
         */
        const email = emailRegex.test(search.email) ? search.email : undefined;
        delete search.email;
        dispatch({
            type: 'AUTH:LOGIN-PENDING'
        })
        auth = new Auth({
            clientID,
            domain,
            audience
        }, {baseUrl: externals.portalFrontend});

        window.__auth = auth;

        dispatch({
            type: "AUTH:REFRESH-SSO",
            payload: { email }
        });
    } else if (type === "AUTH:REFRESH-SSO") {
        const email  = payload?.email;
        const loginSuccess = (authResult) => {
            const userId = _.get(authResult, "idTokenPayload.sub");
            setAccessToken(authResult.accessToken);
            setUserId({userId});
            setGoogleAnalytics(authResult);
            setFullStory(authResult);
            setDealTaleAnalytics(authResult);

            dispatch({
                type: "AUTH:REFRESH-SSO-SUCCESS",
                payload: authResult
            });

            dispatch({ type: "APP:HANDLE-GTC-CHECK" });
            
            setTimeout(() => {
                dispatch({
                    type: "AUTH:RENEW-SSO"
                });
            }, authResult.expiresIn * 1000 - 35000);

            setTimeout(() => {
                dispatch({
                    type: "AUTH:AUTO-LOGOUT"
                });
            }, 3 * 60 * 60 * 1000);

            trackEvent({
                event_category: 'user_login',
                action: "BlueXP_UI_login",
                label: 'UserId',
                value : userId
            });

            handleLocationReplacements(authResult);
        };

        const loginFail = () => {
            dispatch({
                type: "AUTH:REFRESH-SSO-FAILED",
                payload: {email}
            });
        };

        auth.refreshSso(undefined, {pathname: window.location.pathname + window.location.search + window.location.hash}, undefined, email).then(loginSuccess, loginFail);
    } else if (type === "AUTH:REFRESH-SSO-FAILED") {
        trackGaBeforeLogin();
        const paramsForLoginPage = {}
        const email = payload?.email;
        const match = matchPath(window.location.pathname,'/app-redirect/:app')
        if (email) {
            paramsForLoginPage.email = email
        }
        if(match){
            paramsForLoginPage.service = match.params.app
        }
        auth.loginRedirect({}, paramsForLoginPage, {pathname: window.location.pathname + window.location.search + window.location.hash});
    } else if (type === "AUTH:LOGOUT") {
        auth.logout();
    } else if (action.type === "AUTH:RENEW-SSO") {
        const loginSuccess = (authResult) => {
            setAccessToken(authResult.accessToken);

            const currentUserId = _.get(getState(), "auth.userId");
            const newUserId = _.get(authResult, "idTokenPayload.sub");

            if (currentUserId !== newUserId) {
                console.log("Newly logged in user is different from previous.. reloading app..");
                window.location.reload();
            }

            dispatch({
                type: "AUTH:REFRESH-SSO-SUCCESS",
                payload: authResult
            });

            setTimeout(() => {
                dispatch({
                    type: "AUTH:RENEW-SSO"
                });
            }, authResult.expiresIn * 1000 - 35000);

        };

        const loginFail = (err) => {
            if (err.error === "timeout") {
                dispatch({
                    type: "AUTH:RENEW-SSO-TIMEOUT"
                });
            } else {
                dispatch({
                    type: "AUTH:REFRESH-SSO-FAILED"
                });
            }
        };

        auth.refreshSso().then(loginSuccess, loginFail);
    } else if (type === "AUTH:AUTO-LOGOUT") {
        auth.logout();
    } else if (type === "AUTH:THIRD-PARTY-AUDIENCE") {
        const state = getState();
        if(!state.auth.isFetchingThirdPartyToken && !state.auth.thirdPartyToken) {
            const p = handlePromiseAction(action, () => {
                return auth.refreshSso({audience: "https://3p1c.cloud.netapp.com"}, null, true);
            })

            p.catch(e => {
                dispatch({
                    type: "NOTIFICATIONS:ERROR",
                    payload: {
                        message: "Failed to obtain third party access token.",
                        moreInfo: e?.errorDescription
                    }
                })
            })
        }
    }

    return next(action);
}
